import React from "react"
import CookieConsent from "react-cookie-consent"
import {useTranslation} from 'gatsby-plugin-react-i18next'

const Cookies = () => {
    const {t} = useTranslation(['footer'])

    return(
        <CookieConsent
            location='bottom'
            buttonText={t('cookiesBtn')}
            cookieName='cookie'
            style={{ 
                background: '#150e04',
                justifyContent: 'center',
                alignItems: 'center',
                borderTop: '1px solid #fff',
                zIndex: '98'
            }}
            contentStyle={{
                fontFamily: 'arial, serif',
                textTransform: 'uppercase',
                letterSpacing: '1px',
                fontSize: '11px',
                lineHeight: '15px',
                color: '#fff',
                maxWidth: 'fit-content',
                textAlign: 'justify'
            }}
            buttonStyle={{ 
                color: '#000000',
                background: '#fff',
                fontFamily: 'arial, serif',
                textTransform: 'uppercase',
                letterSpacing: '1px',
                fontSize: '11px',
                lineHeight: '15px',
                padding: '10px 20px'
            }}
            expires={7}
        >
            {t('cookies')}
        </CookieConsent>
    )
}

export default Cookies