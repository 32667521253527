import React, { useEffect } from "react"
import PropTypes from "prop-types"
import "./layout.css"
import Cookies from "../cookies/cookies"

const Layout = ({ children, color }) => {

  useEffect(() => {
    document.querySelector("body").style.position = 'unset';
    document.querySelector("body").style.overflow = 'unset';
  });

  return (
    <main style={{background: color}}>
      {children}
      <Cookies/>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout